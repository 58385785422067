@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"















































=centered
  display: flex
  justify-content: center
  align-items: center

.assignments-list
  position: relative

  &__title
    display: block
    width: 90px
    margin-bottom: $size-s

    .sas-skeleton-loader
      height: 23px !important

      +mq-l--mf
        height: 29px !important

  &__item
    margin-bottom: $size-s

    &__card
      flex-flow: row nowrap
      justify-content: space-between
      align-items: center

      +mq-m--mf
        display: flex
        padding-left: $size-m !important
        padding-right: $size-m !important

      &__details
        .assignments-skeleton
          display: block
          width: 150px
          margin-bottom: 10px

        .title-skeleton
          display: block
          width: 300px
          margin-top: 14px

          .sas-skeleton-loader
            height: 21px !important

          +mq_s
            width: 200px

          +mq_m
            margin-bottom: 10px

      .button-skeleton
        display: block
        width: 90px

        .sas-skeleton-loader
          height: 36px !important
