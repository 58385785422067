@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"



















.tabs-skeleton
  display: flex

  .sas-skeleton-loader
    margin: 8px $size-xs
    +space-inline($size-m)
