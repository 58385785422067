@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"
















































































.tabs
  overflow: auto
  +flex-center-start

  &__item
    +space-inline($size-s)
    border-radius: 0
    flex-shrink: 0

    &::after
      position: absolute
      bottom: 0
      content: ''
      display: block
      height: 5px
      width: 0%
      border-radius: 10px 10px 0 0
      background: $color-primary-dark
      +transition(.3s)

    ::v-deep .sas-button__text
      color: $color-ink-light

    &.--active,
    &.router-link-active
      &::after
        width: 100%

      ::v-deep .sas-button__text
        color: $color-ink

  &.--dark
    .tabs__item
      &::after
        background-color: $color-white

      &.--active,
      &.router-link-active
        ::v-deep .sas-button__text
          color: $color-white
          opacity: 1
          font-size: $font-size-s
          line-height: 20px

      ::v-deep .sas-button__text
        color: $color-white
        opacity: 0.7
