@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"




























.assignment-section-skeleton
  margin-bottom: $size-xl

  &__title
    margin-bottom: $size-xs

  &__subtitle
    margin-bottom: $size-m
