// -------------------------------------------------------------------
// MEDIA QUERIES
// -------------------------------------------------------------------
=mq-xs--mf
  @media only screen and (min-width: $device-xs)
    @content

=mq-s--mf
  @media only screen and (min-width: $device-s)
    @content

=mq-m--mf
  @media only screen and (min-width: $device-m)
    @content

=mq-l--mf
  @media only screen and (min-width: $device-l)
    @content

=mq-xl--mf
  @media only screen and (min-width: $device-xl)
    @content

=mq-media--mf($width)
  @media only screen and (min-width: $width)
    @content

=mq-landscape
  @media only screen and (orientation: landscape)
    @content

=mq-landscape--mf($width)
  @media only screen and (min-width: $width) and (orientation: landscape)
    @content

=mq-portrait
  @media only screen and (orientation: portrait)
    @content

=mq-portrait--mf($width)
  @media only screen and (min-width: $width) and (orientation: portrait)
    @content
