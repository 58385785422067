@import "~@sas-te/alfabeto-tokens/src/scss/mixins"
@import "~@sas-te/alfabeto-tokens/src/scss/functions"
@import "~@sas-te/alfabeto-tokens/src/scss/variables"
@import "~@/styles/variables"
@import "~@/styles/mixins"
@import "~@/styles/media"


















































































$shadow: linear-gradient(215deg, rgba(0, 0, 0, 0) 25%, rgba(0, 0, 0, 0.25) 100%)

.subject-header
  position: relative
  transition: background-color $speed-x-slow ease

  &::before
    +cover(absolute)
    +pseudo
    background: $shadow

  &__text
    position: relative
    +flex-center-start
    padding: $size-xl 0
    color: $color-white
    font-size: $font-size-heading-3
    z-index: 1

    &__title
      margin-left: $size-xs
