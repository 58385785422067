@import "~@sas-te/alfabeto-tokens/src/scss/mixins";
@import "~@sas-te/alfabeto-tokens/src/scss/functions";
@import "~@sas-te/alfabeto-tokens/src/scss/variables";
@import "~@/styles/variables";
@import "~@/styles/mixins";
@import "~@/styles/media";







































































.subject {
  .header {
    min-height: 170px;
    width: 100%;
    background-color: purple;
    color: $color-white;
    margin-bottom: $size-xl;
    text-align: center;
  }

  &__wrapper {
    padding: {
      top: $size-l;
      bottom: $end-space;
    }
  }
}
